export const Features = (props) => {
  return (
    <div id="features" className="">
      <div className="section-title">
        <h2>Features</h2>
      </div>
      <div className="features">
        {props.data
          ? props.data.map((d, i) => (
              <div key={`${d.title}-${i}`} className="feature">
                {' '}
                <img
                  alt={`${d.icon}`}
                  src={`svg/${d.icon}_icon.svg`}
                  className="feature-icon"
                ></img>
                <h3>{d.title}</h3>
                <p>{d.text}</p>
              </div>
            ))
          : 'Loading...'}
      </div>
    </div>
  );
};

//src="svg/hardwood_icon.svg"
