export const Gallery = (props) => {
  return (
    <>
      <div
        id="portfolio"
        className="portfolio"
        onContextMenu={(e) => e.preventDefault()}
      >
        <div className="section-title">
          <h2>Gallery</h2>
          <p>List of available doors</p>
        </div>
        <div className="gallery">{getAnimalsContent()}</div>
      </div>
    </>
  );
};

const Picture = (props) => {
  return (
    <div className="gallery-item">
      <img
        src={`https://raw.githubusercontent.com/MuniAshutosh/sbd-assets/main/pics/SBD%20-%20${props.num}_thumbnail.webp`}
        className=""
        alt={`SBD${props.num}`}
        width="200"
        height="400"
      />
      <p>{`SBD${props.num}`}</p>
    </div>
  );
};

const getAnimalsContent = () => {
  let content = [];
  for (let i = 1; i <= 80; i++) {
    content.push(<Picture key={i} num={String('0' + i).slice(-2)} />);
  }
  console.log('rendering');
  return content;
};
