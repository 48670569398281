import { useState } from 'react';

const initialState = {
  name: '',
  email: '',
  phone: '',
  message: '',
};
export const Contact = (props) => {
  const [{ name, email, phone, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const clearScreen = () => {
    setTimeout(function () {
      clearState();
      document.getElementById('success').innerText = '';
    }, 10000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const body =
      phone !== ''
        ? {
            name: name,
            email: email,
            phone: phone,
            message: message,
          }
        : {
            name: name,
            email: email,
            message: message,
          };

    fetch(props.data.mail_api_url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }).then((res) => handleSubmitResponse(res));
  };

  const handleSubmitResponse = (res) => {
    if (res.status === 201) {
      document.getElementById('success').innerText = props.data.success;
    } else if (res.status === 422) {
      console.log(res.status);
      document.getElementById('success').innerText = props.data.failure;
    } else if (res.status === 429) {
      console.log(res.status);
      document.getElementById('success').innerText = props.data.toomany;
    } else {
      console.log(res.status);
      document.getElementById('success').innerText = props.data.failure;
    }
    clearScreen();
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                        value={name}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        value={email}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="form-control"
                        pattern="[0-9]{10}"
                        placeholder="Phone number"
                        onChange={handleChange}
                        value={phone}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    value={message}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.google.com/?q=Shri%20Baidyanath%20Doors,Bhejiput,%20Cuttack%20-%20Puri%20Bypass%20Rd,%20near%20by%20Pass%20Square,%20College%20Road,%20Bhanjanagar,%20Odisha%20761126"
                >
                  {props.data ? props.data.address : 'loading'}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{' '}
                <a href={`tel:+91${props.data ? props.data.phone : 'loading'}`}>
                  {' '}
                  {props.data ? props.data.phone : 'loading'}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{' '}
                <a href={`mailto:${props.data ? props.data.email : 'loading'}`}>
                  {props.data ? props.data.email : 'loading'}
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2020 Issaaf Kattan React Land Page Template. Design by{' '}
            <a href='http://www.templatewire.com' rel='nofollow'>
              TemplateWire
            </a>
          </p>
        </div>
      </div> */}
    </div>
  );
};
